<template>
  <div v-if='Sozlesme != null'
    class="WordSection1"
  >
    <button
      class="print"
      @click="Yazdir"
    >
      YAZDIR
    </button>
    <!-- Sözleşme  Teması BAŞLANGIÇ.-->
    <!-- Sözleşme  Teması BAŞLANGIÇ.-->
    <!-- Sözleşme  Teması BAŞLANGIÇ.-->
    <!-- Sözleşme  Teması BAŞLANGIÇ.-->
    <!-- Sözleşme  Teması BAŞLANGIÇ.-->
    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <b><span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">Zwischen</span></b>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
150%"
    >
      <span style="font-size:9.0pt;line-height:150%;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
150%"
    >
      <span style="font-size:9.0pt;line-height:150%;font-family:&quot;Arial&quot;,sans-serif">{{
        Sozlesme.firma || '' }}</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
150%"
    >
      <span style="font-size:9.0pt;line-height:150%;font-family:&quot;Arial&quot;,sans-serif">{{
        Sozlesme.firma_cadde || '' }}</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">{{
        Sozlesme.firma_sehir || '' }}</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">-
        nachstehend <b>Arbeitgeber</b> genannt - </span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <b><span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">und </span></b>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">Herrn </span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">{{
        Sozlesme.isim || '' }} {{ Sozlesme.soyisim || '' }}</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;line-height:normal"
    >
      <span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >{{ Sozlesme.personel_cadde
        || '' }}</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">{{
        Sozlesme.personel_sehir || '' }}</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">-
        nachstehend <b>Mitarbeiter</b> genannt -</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.4pt;line-height:normal"
    >
      <span
        style="font-size:9.0pt;font-family:
&quot;Arial&quot;,sans-serif"
      >&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;line-height:normal"
    >
      <span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >wird folgender
        Arbeitsvertrag geschlossen. </span>
    </p>

    <p
      class="MsoNormal"
      style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.4pt;line-height:normal"
    >
      <span
        style="font-size:9.0pt;font-family:
&quot;Arial&quot;,sans-serif"
      >&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">Die in
        diesem Vertragstext verwendete Bezeichnung Mitarbeiter sowie sonstige
        Personenbezeichnungen erfolgen geschlechtsunabhängig. Sie werden ausschließlich
        aus Gründen der besseren Lesbarkeit verwendet.</span>
    </p>

    <p class="MsoNormal">
&nbsp;
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <b><span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">1
        Inhalt / Inbezugnahme von Tarifverträgen </span></b>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">(1) Der
        Arbeitgeber überlässt als Personaldienstleistungsunternehmen seinen Kunden
        Mitarbeiter im Rahmen der Arbeitnehmerüberlassung. Der Arbeitgeber besitzt eine
        befristet Erlaubnis zur Arbeitnehmerüberlassung, zuletzt erteilt von der
        Bundesagentur für Arbeit, Agentur für Arbeit Düsseldorf, am {{ Sozlesme.firma_aug_izin_tarihi || '' }}
        in Düsseldorf.</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="text-align:justify"
    >
      <span
        style="font-size:9.0pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif"
      >Der Mitarbeiter wird an
        wechselnden Einsatzstellen bei Kunden und bei wechselnden Kunden eingesetzt.
        Der Einsatz bei einem Kunden erfolgt vorübergehend. Der Arbeitgeber informiert
        den Mitarbeiter vor jeder Überlassung darüber, dass er als Zeitarbeitnehmer
        tätig wird. Der Mitarbeiter kann auch im Rahmen von Werk- oder Dienstverträgen
        eingesetzt werden.</span>
    </p>

    <p
      class="MsoNormal"
      style="text-align:justify"
    >
      <span
        style="font-size:9.0pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif;color:black"
      >(2) Die Rechte und
        Pflichten der Arbeitsvertragsparteien bestimmen sich nach den Tarifverträgen in
        der jeweils gültigen Fassung, die der Arbeitgeberverband iGZ mit einer oder
        mehreren der Gewerkschaften IG BCE, NGG, IG Metall, GEW, ver.di, IG Bau, GdP,
        EVG abgeschlossen hat oder zukünftig abschließen wird. Die Tarifverträge liegen
        zur Einsichtnahme in den Geschäftsräumen aus. Es finden dabei nicht sämtliche
        vom iGZ abgeschlossenen Tarifverträge gleichzeitig auf das Arbeitsverhältnis
        Anwendung, sondern nur die einschlägigen Tarifverträge nach der in den Absätzen
        3 bis 5 genannten Maßgabe.</span>
    </p>

    <p
      class="MsoNormal"
      style="text-align:justify"
    >
      <span
        style="font-size:9.0pt;
line-height:107%;font-family:&quot;Arial&quot;,sans-serif;color:black"
      >(3) Es finden
        jeweils diejenigen der in Absatz 2 genannten Tarifverträge Anwendung, an denen
        die Gewerkschaft, aus deren Satzung sich die Zuständigkeit für den zugewiesenen
        Kundenbetrieb ergibt, als Vertragspartei beteiligt ist. Soweit nach dem
        Vorstehenden die satzungsgemäße Zuständigkeit mehrerer Gewerkschaften begründet
        ist, finden die Tarifverträge mit derjenigen in Absatz 2 genannten zuständigen
        Gewerkschaft Anwendung, die im Verhältnis zu der oder den anderen zuständigen
        Gewerkschaft /Gewerkschaften in Absatz 2 zuerst genannt wird. <br>
        <br>
        (4) Bis zum Beginn des ersten Einsatzes finden diejenigen mit dem iGZ
        abgeschlossenen Tarifverträge Anwendung, an denen ver.di als Vertragspartei
        beteiligt ist. Ab Beginn des ersten Einsatzes gelten diejenigen nach Maßgabe
        des Absatzes 3 ermittelten Tarifverträge solange, bis ein anderer Einsatz
        beginnt.  <br>
        <br>
        (5) Soweit der Mitarbeiter an einen Kundenbetrieb überlassen wird, für den sich
        keine satzungsgemäße Zuständigkeit für den jeweiligen Kundenbetrieb ergibt,
        finden diejenigen mit dem iGZ abgeschlossenen Tarifverträge Anwendung, an denen
        ver.di als Vertragspartei beteiligt ist.</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <b><span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;
color:black"
      >2 Beginn / Probezeit / Dauer </span></b>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">(1) Das
        Arbeitsverhältnis beginnt am {{ Sozlesme.baslama_tarihi }}</span><b><span
        style="font-size:9.0pt;font-family:
&quot;Arial&quot;,sans-serif"
      >{{ BAS }}.</span></b><span
        style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif"
      > </span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">Es ist
        eine Probezeit von 6 Monaten gemäß § 2.2. Manteltarifvertrag iGZ mit den dort
        genannten Kündigungsfristen vereinbart. Probezeiten und Kündigungsfristen
        gelten gleichermaßen bei einer Befristung dieses Arbeitsverhältnisses. </span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <b><span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span></b>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">Erscheint
        der Mitarbeiter am ersten Arbeitstag nicht und benachrichtigt den Arbeitgeber
        nicht unverzüglich über die Verhinderung am ersten Arbeitstag, so gilt das
        Arbeitsverhältnis als nicht zustande gekom-men (§ 2.1. Manteltarifvertrag iGZ).</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">(2) Die
        Vertragsparteien vereinbaren, dass </span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoListParagraph"
      style="margin-bottom:0cm;text-indent:-18.0pt;
line-height:normal"
    >
      <span style="font-size:9.0pt;font-family:Wingdings">&thorn;<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp; </span></span><span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >das Arbeitsverhältnis
        unbefristet abgeschlossen wird.</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;line-height:normal"
    >
      <span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal"
    >
      <span
        style="font-size:9.0pt;font-family:Wingdings"
      >¨<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;
      </span></span><span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">das
        Arbeitsverhältnis gemäß § 14 Absatz 2 Teilzeit- und Befristungsgesetz befristet
        (reguläre Befristung) wird. Es endet am ___________. Der Mitarbeiter bestätigt,
        dass zu keinem Zeitpunkt vor Beginn dieses Arbeitsverhältnisses bereits ein
        Arbeitsverhältnis zwischen den Vertragsparteien bestanden hat.</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal"
    >
      <span
        style="font-size:9.0pt;font-family:Wingdings"
      >¨<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;
      </span></span><span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">das
        Arbeitsverhältnis gemäß § 14 Absatz 2a Teilzeit- und Befristungsgesetz
        befristet (bis zu 4 Jahre nach Neugründung eines Unternehmens) wird. Es endet
        am _______________. Der Mitarbeiter bestätigt, dass zu keinem Zeitpunkt vor
        Beginn dieses Arbeitsverhältnisses bereits ein Arbeitsverhältnis zwischen den
        Vertragsparteien bestanden hat.</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal"
    >
      <span
        style="font-size:9.0pt;font-family:Wingdings"
      >¨<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;
      </span></span><span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">das
        Arbeitsverhältnis gemäß § 14 Absatz 3 Teilzeit- und Befristungsgesetz befristet
        (Befristung älterer Mitarbeiter (ab 52 Jahre), wenn vor Arbeitsbeginn
        mindestens vier Monate beschäftigungslos) wird. Es endet am _______________.</span>
    </p>

    <p class="MsoListParagraph">
      <span
        style="font-size:9.0pt;line-height:107%;
font-family:&quot;Arial&quot;,sans-serif"
      >&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal"
    >
      <span
        style="font-size:9.0pt;font-family:Wingdings"
      >¨<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;
      </span></span><span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">das
        Arbeitsverhältnis gemäß § 14 Absatz 1 Satz 2 Nr. __ Teilzeit-
        und Befristungsgesetz für die Dauer ________________________________ <br>
        [z.B. Nr. 3: Vertretung eines erkrankten Zeitarbeitnehmers des Arbeitgebers]
        befristet (Befristung mit Sachgrund) wird. Das Arbeitsverhältnis endet mit
        Erreichung des Zwecks, frühestens aber 14 Tage nach Mitteilung der
        Zweckerreichung durch den Arbeitgeber, ohne dass es einer Kündigung bedarf.</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal"
    >
      <span
        style="font-size:9.0pt;font-family:Wingdings"
      >¨<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;
      </span></span><span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">das
        Arbeitsverhältnis auf Wunsch des Mitarbeiters [z.B. Studium] nach § 14 Absatz 1
        Satz 2 Nr. 6 Teilzeit- und Befristungsgesetz befristet wird. Es endet am
        _________. Die Gründe für die gewünschte Befristung sind der beigefügten
        Erklärung des Mitarbeiters zu entnehmen. </span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <b><span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;
color:black"
      >3 Tätigkeit / Pflichten des Mitarbeiters</span></b>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black">(1)
        Der Mitarbeiter wird entsprechend der Tätigkeit im Kundenbetrieb eingestellt
        als {{ Sozlesme.meslek }}. Dem Mitarbeiter obliegen </span><span
        style="font-size:8.0pt;
font-family:&quot;Arial&quot;,sans-serif;color:black"
      > </span>
    <!--  <span
        style="font-size:
9.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black"
      >obliegen</span> -->
      
      <span
        style="font-size:8.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black"
      > </span><span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black"
      >z.B. </span><span
        style="font-size:8.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black"
      > </span><span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black"
      >folgenden</span><span
        style="font-size:8.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black"
      > </span><span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black"
      >Tätigkeiten:</span><b><span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black"
      >
        Instandhaltungsarbeiten in Industrieanlagen und Kraftwerken. Montage und
        Demontage von Kesseln, Behältern in  Industrieanlagen, Schlossertätigkeiten.
        Das Vorbereiten von Schweißarbeiten an Kessel und Behältern. Schweißarbeiten
        Schlosserhelfertätigkeiten.</span></b>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black">Aus
        der Einsatzanweisung vor Beginn des Einsatzes in einem Kundenbetrieb können
        sich abweichende oder ergänzende Tätigkeiten ergeben. </span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">(2)<span
        style="color:black"
      > Der Mitarbeiter wird an verschiedenen Einsatzorten im
        Gebiet Europaweit bei Kundenbetrieben beschäftigt. Er ist bei Bedarf auch zur
        Arbeitsleistung an Einsatzorten außerhalb des Kundenbetriebes verpflichtet. Der
        Arbeitgeber ist berechtigt, den Mitarbeiter jederzeit vom Kundeneinsatz
        abzuberufen und anderweitig einzusetzen.</span></span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">(3)<span
        style="color:red"
      > </span><span style="color:black">Dem Mitarbeiter können auch
        interne Tätigkeiten im Betrieb des Arbeitgebers zugewiesen werden (kein
        Drittpersonaleinsatz). Eine Verringerung des Vergütungsanspruchs tritt dadurch
        nicht ein. </span>Die Zuweisung von internen Tätigkeiten darf einen
        ununterbrochenen Zeitraum von vier Wochen nicht überschreiten. </span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black">(4)
        Der Mitarbeiter hat sich die geleisteten Arbeitsstunden pro Kunde und
        Kalenderwoche vom Kunden bestätigen zu lassen. Der Nachweis kann über eine
        elektronische Arbeitszeiterfassung beim Kunden oder über die vom Arbeitgeber
        ausgehändigten Tätigkeitsnachweise erfolgen. Die unterschriebenen
        Tätigkeitsnachweise oder der Auszug der elektronisch erfassten Arbeitsstunden
        sind umgehend, jeweils wöchentlich beim Arbeitgeber einzureichen. </span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <b><span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;
color:black"
      >4 Arbeitszeit / Mehrarbeit / Arbeitszeitkonto</span></b>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <b><span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;
color:black"
      >&nbsp;</span></b>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;line-height:normal"
    >
      <span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black"
      >(1) Es wird
        auf die Regelungen zur Arbeitszeit in § 3.1. Manteltarifvertrag iGZ verwiesen. </span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal"
    >
      <span
        style="font-size:9.0pt;font-family:Wingdings"
      >¨<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;
      </span></span><span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">Der
        Mitarbeiter arbeitet in Vollzeit. Die Vertragsparteien vereinbaren eine
        individuelle regelmäßige monatliche Arbeitszeit gemäß § 3.1.1.
        Manteltarifvertrag iGZ, die 151,67 Stunden beträgt.</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:18.0pt;text-align:justify;line-height:normal"
    >
      <span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black"
      >&nbsp;</span>
    </p>

    <p
      class="MsoListParagraph"
      style="margin-bottom:0cm;text-align:justify;
text-indent:-18.0pt;line-height:normal"
    >
      <span
        style="font-size:9.0pt;
font-family:Wingdings"
      >&thorn;<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;
      </span></span><span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">Der
        Mitarbeiter arbeitet in Vollzeit. Die Vertragsparteien vereinbaren eine
        individuelle regelmäßige Arbeitszeit       pro Monat  gemäß § 3.1.2.
        Manteltarifvertrag iGZ, die sich nach der Anzahl der Arbeitstage richtet. </span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal"
    >
      <span
        style="font-size:9.0pt;font-family:Wingdings"
      >¨<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;
      </span></span><span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">Der
        Mitarbeiter arbeitet in Teilzeit. Die Vertragsparteien vereinbaren eine
        individuelle regelmäßige monatliche Arbeitszeit gemäß § 3.1.1.
        Manteltarifvertrag iGZ von ______ Stunden.</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:18.0pt;text-align:justify;text-indent:2.25pt;line-height:normal"
    >
      <span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black"
      >&nbsp;</span>
    </p>

    <p
      class="MsoListParagraphCxSpFirst"
      style="text-align:justify;text-indent:-18.0pt"
    >
      <span
        style="font-size:9.0pt;line-height:107%;font-family:Wingdings"
      >¨<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp; </span></span><span
        style="font-size:9.0pt;line-height:107%;font-family:&quot;Arial&quot;,sans-serif"
      >Der
        Mitarbeiter arbeitet in Teilzeit. Die Vertragsparteien vereinbaren eine
        individuelle regelmäßige Arbeitszeit pro Monat  gemäß § 3.1.2.
        Manteltarifvertrag iGZ, die sich nach der Anzahl der Arbeitstage richtet. Die
        Arbeitszeit beträgt </span>
    </p>

    <p class="MsoListParagraphCxSpMiddle">
      <span
        style="font-size:9.0pt;line-height:
107%;font-family:&quot;Arial&quot;,sans-serif"
      >&nbsp;</span>
    </p>

    <p class="MsoListParagraphCxSpLast">
      <span
        style="font-size:9.0pt;line-height:
107%;font-family:&quot;Arial&quot;,sans-serif"
      >in Monaten mit 20 Arbeitstagen ___
        Stunden, <br>
        in Monaten mit 21 Arbeitstagen ___ Stunden, <br>
        in Monaten mit 22 Arbeitstagen ___ Stunden, <br>
        in Monaten mit 23 Arbeitstagen ___ Stunden.<br>
        <br>
      </span>
    </p>

    <p
      class="MsoNormal"
      style="margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal"
    >
      <span style="font-size:9.0pt;font-family:Wingdings">¨<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp; </span></span><span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >Optional zur
        Beschäftigung auf 450 €-Basis:<br>
        Der Mitarbeiter wird im Rahmen einer geringfügigen Beschäftigung gemäß § 8 Abs.
        1 Nr. 1 SGB IV eingesetzt. Der Arbeitgeber weist den Mitarbeiter darauf hin,
        dass der Mitarbeiter den pauschalen Rentenversicherungsbeitrag des Arbeitgebers
        (derzeit 15 %) mit einem eigenen Beitrag von derzeit  3,6 % auf die vollen 18,6
        % aufstocken muss. Damit erwirbt der Mitarbeiter den vollen Schutz in der
        gesetzlichen Rentenversicherung. Der Mitarbeiter kann sich jedoch durch
        schriftlichen Antrag beim Arbeitgeber von der Rentenversicherungspflicht
        befreien lassen. In diesem Fall gilt die Befreiung von der
        Rentenversicherungspflicht für die gesamte Dauer des
        Beschäftigungsverhältnisses.</span>
    </p>

    <p
      class="MsoNormal"
      style="text-align:justify;line-height:normal"
    >
      <span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >(2) Der Mitarbeiter
        verpflichtet sich im Rahmen des gesetzlich Zulässigen Überstunden und
        Mehrarbeit zu leisten<span style="color:red">.</span></span>
    </p>

    <p
      class="MsoNormal"
      style="text-align:justify;line-height:normal"
    >
      <span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >(3) Der Arbeitgeber
        richtet für den Mitarbeiter gemäß § 3.2. Manteltarifvertrag iGZ ein
        Arbeitszeitkonto ein. </span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <b><span
        lang="X-NONE"
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >5
        Vergütung und Fälligkeit / Aufwendungsersatz </span></b>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">(1) Der
        Mitarbeiter wird gemäß §§ 2 Absatz 1, 3 Entgeltrahmentarifvertrag iGZ
        eingruppiert in die Entgeltgruppe {{ Sozlesme.ucret_grubu || '' }}. </span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">(2) Der
        Mitarbeiter erhält ein <b>tarifliches Entgelt </b>(Entgeltgruppe), dessen Höhe
        sich nach § 2 Entgelttarifvertrag iGZ in Verbindung mit § 4
        Entgeltrahmentarifvertrag iGZ bemisst. Es beträgt derzeit</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="einzug1"
      align="center"
      style="margin:0cm;text-align:center"
    >
      <b><span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >{{ Sozlesme.saat_ucreti }}€</span></b><span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      > brutto pro Stunde </span>
    </p>

    <p
      class="einzug1"
      style="margin:0cm;text-align:justify"
    >
      <span
        style="font-size:
9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;line-height:normal"
    >
      <span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >Zzgl. übertarifliche
        Zulage i.H. <b>{{ Sozlesme.ek_ucret }}</b>€<br>
        Zusätzlich erhält der Mitarbeiter eine Schmutzzulage i.H. von 0,40€ /
        Geleistete Stunde</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">(3) Bis
        zum 31.03.2021 gilt:</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">Wird der
        Mitarbeiter in Betriebe in den neuen Bundesländern Mecklenburg-Vorpommern,
        Brandenburg, Berlin, Sachsen-Anhalt, Thüringen und Sachsen überlassen, richten
        sich die Entgelte nach den Entgelttabellen Ost (§ 3 Entgelttarifvertrag iGZ).
        Für das Mindeststundenentgelt nach § 3 Nr. 1 des Tarifvertrags zur Regelung von
        Mindeststundenentgelten in der Zeitarbeit gilt das Mindeststundenentgelt des
        Arbeitsorts. Wird der Mitarbeiter auswärtig beschäftigt, behält er jedoch den
        Anspruch auf das Mindeststundenentgelt seines Einstellungsortes, soweit dieses
        höher ist.</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"> </span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">(4) Ein
        Anspruch auf Branchenzuschläge ergibt sich nach den jeweils geltenden
        Tarifverträgen über Branchenzuschläge für Arbeitnehmerüberlassungen.</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">(5) Das
        tarifliche Entgelt erhält der Mitarbeiter auch für Zeiten, in denen er nicht
        verliehen ist (Nachweis gemäß § 11 Absatz 1 Satz 2 Nr. 2 AÜG). In diesen Zeiten
        besteht kein Anspruch auf Branchenzuschlag. Um sich den Anspruch nach Satz 1 zu
        sichern, muss der Mitarbeiter montags bis freitags, morgens von ________ Uhr
        bis ________ Uhr und nachmittags von ________ Uhr bis ________ Uhr telefonisch
        erreichbar sein (höchstens 2 x 1,5 Stunden pro Tag), um sich Einsätze zuweisen
        zu lassen.</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">                      </span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">(6) Ein
        Anspruch auf Zuschläge, die in Abhängigkeit von der Dauer oder der Lage der
        Arbeitszeit geleistet werden, richtet sich insbesondere nach § 4
        Manteltarifvertrag iGZ. Ein Anspruch auf eine einsatzbezogene Zulage richtet
        sich nach § 5 Entgeltrahmentarifvertrag iGZ, ein Anspruch auf
        Jahressonderzahlungen nach § 8 Manteltarifvertrag iGZ.</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <a name="OLE_LINK3"></a><a name="OLE_LINK4"><span
        style="font-size:
9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >&nbsp;</span></a>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">(7) Die
        Vergütung ist auf ein vom Mitarbeiter anzugebendes Konto zu überweisen. Die
        Fälligkeit richtet sich nach § 11 Manteltarifvertrag iGZ.</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">(8) Der
        Anspruch auf Aufwendungsersatz bestimmt sich nach § 670 BGB. Es können für
        jeden Einsatz gesonderte Vereinbarungen getroffen werden. Als erste
        Tätigkeitsstätte im Sinne des steuerlichen Reisekostenrechts wird der Betrieb
        des Arbeitgebers festgelegt, die betreuende Niederlassung unter folgender
        Anschrift: </span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">__________________________________________________________________________________</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">__________________________________________________________________________________</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <b><span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span></b>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <b><span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span></b>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <b><span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span></b>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <b><span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">6
        Anrechnungsvorbehalt</span></b>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <a name="OLE_LINK1"></a><a name="OLE_LINK2"><span
        style="font-size:
9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >&nbsp;</span></a>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">Über- und
        außertarifliche Zulagen mindern sich, ohne dass es einer entsprechenden
        Erklärung des Arbeitgebers bedarf, um den Betrag einer etwaigen zukünftigen
        oder rückwirkenden Erhöhung des tariflichen Entgelts (insbesondere Anspruch auf
        einsatzbezogene Zulage, Erhöhung als Ergebnis von Tarifverhandlungen, auch im
        Falle von Einmalzahlungen, Wechsel des Einsatzgebiets, Anspruch auf
        Branchenzuschlag, Höhergruppierung). Im Falle einer rückwirkenden Erhöhung des
        tariflichen Entgelts mindern sich<b> </b>über- und außertarifliche Zulagen
        rückwirkend auf den Zeitpunkt der Erhöhung.</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <b><span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span></b>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <b><span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">7
        Widerrufsvorbehalt</span></b>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">(1) Die
        Vertragsparteien vereinbaren, dass über- und außertarifliche Zulagen als
        widerrufliche Leistungen des Arbeitgebers erbracht werden. Das Widerrufsrecht
        besteht auch für über- und außertarifliche Zulagen, die befristet auf einen
        bestimmten Einsatz bei einem Kundenunternehmen gezahlt werden. Ein Widerruf entfaltet
        sofortige Wirkung. Der widerrufliche Teil ist begrenzt auf 24,9% der
        Gesamtvergütung.</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">(2) Der
        Arbeitgeber ist zum Widerruf einer über- und außertariflichen Zulage aus
        betriebsbedingten Gründen berechtigt. Als  Gründe kommen insbesondere starke
        wirtschaftliche Verluste, unrentabel hohe Betriebskosten, der Wegfall des
        Interesses, bestimmte Mitarbeitergruppen mit einer über- und außertariflichen
        Zulage an das Unternehmen zu binden, die Gefährdung der wirtschaftlichen Bestandsfähigkeit
        des Unternehmens, oder  eine Massenentlassung im Sinne von § 17 KSchG in
        Betracht.</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">(3) Über-
        und außertarifliche Zulagen dürfen auch aus verhaltensbedingten Gründen
        widerrufen werden, wenn der Mitarbeiter mindestens zwei berechtigte Abmahnungen
        innerhalb von sechs Monaten vor Ausspruch der Widerrufserklärung erhalten hat.
        Als Widerrufsgründe kommen auch mangelnde Eignung oder eine erhebliche
        Leistungsminderung in Betracht.</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <b><span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span></b>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <b><span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">8
        Urlaub / Urlaubsentgelt </span></b>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">Es wird
        auf die Urlaubsregelung in § 6 Manteltarifvertrag iGZ verwiesen. Das
        Urlaubsentgelt richtet sich nach § 6a Manteltarifvertrag iGZ. </span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <b><span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">9
        Lohnverpfändung und Lohnabtretung </span></b>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">(1) Der
        Mitarbeiter darf seine Vergütungsansprüche weder verpfänden noch abtreten. </span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">(2) Der
        Arbeitgeber behält sich vor, nachträglich vertragswidrig vorgenommene
        Abtretungen oder Verpfändungen zu genehmigen. </span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">(3) Die
        Kosten, die dem Arbeitgeber durch die Bearbeitung von Pfändungen, Verpfändungen
        und Abtretungen der Vergütungsansprüche des Mitarbeiters entstehen, trägt der
        Mitarbeiter. Diese Kosten werden pauschaliert mit 8,- € pro Pfändung, Abtretung
        und Verpfändung sowie gegebenenfalls zusätzlich 4,- €  für jedes Schreiben
        sowie 1,- € pro Überweisung. Dem Mitarbeiter bleibt der Nachweis vorbehalten,
        dass ein Schaden nicht vorliegt oder wesentlich geringer ist. Bei Nachweis
        höherer tatsächlicher Kosten ist der Arbeitgeber berechtigt, diese in Ansatz zu
        bringen. Mit diesen Kosten kann der Arbeitgeber unter Berücksichtigung der Pfändungsfreigrenzen
        mit Lohnforderungen aufrechnen.  </span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <b><span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">10
        Arbeitsverhinderung / Entgeltfortzahlung im Krankheitsfall </span></b>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">(1) Der
        Mitarbeiter ist verpflichtet, dem Arbeitgeber jede Arbeitsverhinderung
        unverzüglich (ohne schuldhaftes Zögern), möglichst jedoch vor Arbeitsbeginn
        während der betrieblichen Geschäftszeiten, sowie die voraussichtliche Dauer
        anzuzeigen. Auf Verlangen sind die Gründe der Arbeitsverhinderung mitzuteilen;
        dies gilt nicht für den Grund einer Arbeitsunfähigkeit.</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"> </span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">(2) Der Mitarbeiter
        ist verpflichtet, für den ersten Tag einer Arbeitsunfähigkeit am gleichen Tag,
        bei Unzumutbarkeit spätestens am darauf folgenden Kalendertag (außer
        arbeitsfreie Sonn- und Feiertage), eine ärztliche Bescheinigung über das
        Bestehen der Arbeitsunfähigkeit sowie deren voraussichtliche Dauer vorzulegen.
        Dauert die Arbeitsunfähigkeit länger als in der Bescheinigung angegeben, hat
        der Mitarbeiter umgehend eine neue Bescheinigung vorzulegen.</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">(3) Die
        Entgeltfortzahlung im Krankheitsfall richtet sich nach § 6a Manteltarifvertrag
        iGZ.</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <b><span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">11
        Verschwiegenheitsverpflichtungen </span></b>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">(1) Der
        Mitarbeiter verpflichtet sich, über alle Betriebs- und Geschäftsgeheimnisse,
        sowohl während der Dauer des Arbeitsverhältnisses als auch nach seiner
        Beendigung Stillschweigen zu bewahren. Die Geheimhaltungspflicht erstreckt sich
        nicht auf solche Kenntnisse, die jedermann zugänglich sind oder deren
        Weitergabe für den Arbeitgeber ersichtlich ohne Nachteil ist. Im Zweifelsfalle
        sind jedoch technische, kaufmännische und persönliche Vorgänge und
        Verhältnisse, die dem Mitarbeiter im Zusammenhang mit seiner Tätigkeit bekannt
        werden, als Unternehmensgeheimnisse zu behandeln. In solchen Fällen ist der
        Mitarbeiter vor der Offenbarung gegenüber Dritten verpflichtet, eine Weisung der
        Geschäftsleitung einzuholen, ob eine bestimmte Tatsache vertraulich zu
        behandeln ist oder nicht. Die Schweigepflicht erstreckt sich auch auf
        Angelegenheiten anderer Firmen, in denen der Mitarbeiter eingesetzt wird.</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black">(2)
        Über seine Vergütung hat der Mitarbeiter dritten Personen gegenüber
        Stillschweigen zu bewahren. Dies gilt nicht für die Fälle, in denen er
        gesetzlich berechtigt oder verpflichtet ist, Angaben über sein Einkommen zu
        machen, wie beispielsweise dem Finanzamt, der Agentur für Arbeit oder einer sonstigen
        staatlichen Stelle.</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <b><u><span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;
color:blue"
      ><span style="text-decoration:none">&nbsp;</span></span></u></b>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <b><span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">12
        Nebentätigkeit </span></b>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">Jede
        Nebentätigkeit, gleichgültig, ob sie entgeltlich oder unentgeltlich ausgeübt
        wird, bedarf der vorherigen Zustimmung des Arbeitgebers. Die Zustimmung ist zu
        erteilen, wenn die Nebentätigkeit die Wahrnehmung der dienstlichen Aufgaben
        zeitlich nicht oder allenfalls unwesentlich behindert und sonstige berechtigte
        Interessen des Arbeitgebers nicht beeinträchtigt werden. </span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <b><span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span></b>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <b><span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">13
        Beendigung des Arbeitsverhältnisses </span></b>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black">(1)
        Die Kündigungsfristen richten sich nach § 2.2. Manteltarifvertrag iGZ. </span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black">(</span><span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >2<span
        style="color:
black"
      >) Jede fristlos ausgesprochene außerordentliche Kündigung gilt immer als
        zugleich hilfsweise <br>
        ordentlich ausgesprochene Kündigung. Jede nicht die Frist wahrende ordentliche
        Kündigung gilt immer zugleich als zum nächstzulässigen Termin, der sich nach §
        2.2. Manteltarifvertrag iGZ i.V.m. § 622 BGB berechnet, ausgesprochene
        Kündigung.</span></span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal;text-autospace:none"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">(3)
        Stellt der Arbeitgeber den Mitarbeiter unter den Voraussetzungen des § 3.2.5.
        Manteltarifvertrag iGZ frei, werden Guthaben aus dem Arbeitszeitkonto vor
        Urlaubsansprüchen verwendet.</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <b><span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">14
        Unfallverhütung / Arbeitsschutz</span></b>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">Der
        Mitarbeiter wird über die jeweils im Kundenbetrieb geltenden
        Unfallverhütungsvorschriften aufgeklärt. Er verpflichtet sich zur Einhaltung,
        insbesondere (soweit erforderlich) zum Tragen der persönlichen
        Schutzausrüstung. Etwaige Arbeitsunfälle sind dem Arbeitgeber unverzüglich
        anzuzeigen.</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <b><span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">15
        Vertragsstrafen </span></b>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">Regelungen
        zu Vertragsstrafen können sich aus  einem gesonderten Dokument zu diesem
        Arbeitsvertrag ergeben.</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <b><span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">16
        Schriftformklausel </span></b>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">Nebenabreden
        wurden nicht getroffen. Änderungen des Vertrages und Nebenabreden, sowie die
        Änderung dieses Schriftformgebotes bedürfen zu ihrer Wirksamkeit der
        Schriftform. Der Vorrang der Individualabrede gemäß § 305b BGB bleibt davon
        unberührt.</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:2.0cm;text-align:justify;line-height:normal"
    >
      <span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >&nbsp; </span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <b><span
        lang="X-NONE"
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >17
        Vertretungsberechtigung</span></b>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">(1) </span><span
        lang="X-NONE"
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >Auf Arbeitgeberseite
        sind zur Abgabe aller rechts</span><span
        style="font-size:9.0pt;font-family:
&quot;Arial&quot;,sans-serif"
      >-</span><span
        lang="X-NONE"
        style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif"
      >erheblichen Erklärungen, die sich auf die</span><span
        lang="X-NONE"
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      > </span><span
        lang="X-NONE"
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >Begrün</span><span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >-</span><span
        lang="X-NONE"
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >dung,
        Durchführung und Beendigung einschließlich der Kündigung des
        Arbeitsverhältnisses beziehen, der Inhaber / die Geschäftsführer, die Niederlas</span><span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >-</span><span
        lang="X-NONE"
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >sungsleiter
        und die Personaldisponenten berechtigt und zwar jeweils alleinhandelnd. </span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span
        lang="X-NONE"
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">(2) </span><span
        lang="X-NONE"
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >Den Namen </span><span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >der in Absatz 1
        genannten Personen </span><span
        lang="X-NONE"
        style="font-size:9.0pt;font-family:
&quot;Arial&quot;,sans-serif"
      >k</span><span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">ann
        der Mitarbeiter auf folgendem Weg in Erfahrung bringen:</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal"
    >
      <span
        lang="X-NONE"
        style="font-size:9.0pt;font-family:Wingdings"
      >¨<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp; </span></span><span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >Innerhalb der
        Geschäftszeiten der betreuenden Niederlassung von 08:00 Uhr bis 17:00 Uhr</span><span
        lang="X-NONE"
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      > telefonisch
        unter </span><span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">0221
        3795390</span><span
        lang="X-NONE"
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >
        oder </span><span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">per
        Mail </span><span
        lang="X-NONE"
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >über
      </span><span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">___________________________.</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:18.0pt;text-align:justify;line-height:normal"
    >
      <span
        lang="X-NONE"
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal"
    >
      <span
        lang="X-NONE"
        style="font-size:9.0pt;font-family:Wingdings"
      >¨<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp; </span></span><span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >Über den</span><span
        lang="X-NONE"
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      > Aushang am
        „Schwarzen Brett“ in </span><span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">der</span><span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      > </span><span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >betreuenden </span><span
        lang="X-NONE"
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >Niederlassung
      </span><span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">des
        Arbeitgebers, die unter Punkt 5 Absatz 8 als erste Tätigkeitsstätte festgelegt
        worden ist. Der Mitarbeiter muss sich mindestens einmal pro Kalendermonat am
        „Schwarzen Brett“ informieren. </span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <b><span
        lang="X-NONE"
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >18
        Hinweispflicht des Mitarbeiters</span></b>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span
        lang="X-NONE"
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span
        lang="X-NONE"
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >Der
        Mitarbeiter ist verpflichtet,</span><span
        style="font-size:9.0pt;font-family:
&quot;Arial&quot;,sans-serif"
      > dem Arbeitgeber</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal"
    >
      <span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >-<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span><span
        lang="X-NONE"
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >alle
        früheren Arbeitgeber anzugeben, bei denen er in den letzten sechs Monaten vor
        Beginn dieses Arbeitsverhältnisses beschäftigt war</span><span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >,</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal"
    >
      <span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >-<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span><span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">alle
        Kundenunternehmen anzugeben, bei denen er in den letzten vier Monaten vor Beginn
        dieses Arbeitsverhältnisses als Zeitarbeitnehmer eingesetzt war,</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal"
    >
      <span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >-<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span><span
        lang="X-NONE"
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >die
        Gewährung des Zugangs zu Gemeinschafts</span><span
        style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif"
      >-</span><span
        lang="X-NONE"
        style="font-size:
9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >einrichtungen,</span><span
        lang="X-NONE"
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      > </span><span
        lang="X-NONE"
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >von</span><span
        lang="X-NONE"
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      > </span><span
        lang="X-NONE"
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >Sachzuwendungen
        oder sonstigen Leistungen von Seiten des Kunden</span><span
        style="font-size:
9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >-</span><span
        lang="X-NONE"
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >betriebes </span><span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >anzuzeigen</span><span
        lang="X-NONE"
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >,</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal"
    >
      <span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >-<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span><span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">jede
        Änderung seiner Wohnadresse sowie der steuerlichen und sozialversicherungsrechtlichen
        Verhältnisse - insbesondere, wenn neben diesem Beschäftigungsverhältnis ein
        weiteres (geringfügiges) Arbeitsverhältnis aufgenommen wird - unverzüglich
        mitzuteilen, und</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:18.0pt;text-align:justify;text-indent:-18.0pt;line-height:normal"
    >
      <span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >-<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span><span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">jeden
        angekündigten oder bereits stattfindenden Streik im Kundenbetrieb unverzüglich
        mitzuteilen.</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <b><span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span></b>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <b><span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">19
        Datengeheimnis / Datenschutz </span></b>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <b><span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span></b>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">(1) Der
        Mitarbeiter wird auf das Datengeheimnis verpflichtet und auf die Strafbarkeit
        von Verstößen hingewiesen. Es ist untersagt, personenbezogene Daten unbefugt zu
        verarbeiten. Verstöße gegen das Datengeheimnis können nach Artikel 83
        Datenschutz-Grundverordnung i.V.m. §§ 41-43 Bundesdatenschutzgesetz mit
        Bußgeld, Geld- oder Freiheitsstrafe geahndet werden. Die
        Verschwiegenheitsverpflichtung unter Punkt 11 Absatz 1 wird durch diese
        Verpflichtung auf das Datengeheimnis nicht berührt. Die Verpflichtung auf das
        Datengeheimnis besteht auch nach Beendigung des Arbeitsverhältnisses fort.</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <b><span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span></b>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">(2) In
        einem gesonderten Dokument informiert der Arbeitgeber den Mitarbeiter über die
        Verarbeitung seiner personenbezogenen Daten und klärt ihn über seine Rechte und
        Pflichten auf.  </span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span
        lang="X-NONE"
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <b><span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">20 <span
        style="color:black"
      >Einzelvertragliche</span> Ausschlussfristen </span></b>
    </p>

    <p
      class="MsoNormal"
      style="text-align:justify;line-height:normal"
    >
      <span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >Es finden keine
        tariflichen Ausschlussfristen auf dieses Arbeitsverhältnis Anwendung.
        Stattdessen vereinbaren die Vertragsparteien die nachfolgenden
        einzelvertraglichen Ausschlussfristen. </span>
    </p>

    <p
      class="MsoNormal"
      style="text-align:justify;line-height:normal"
    >
      <span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >(1) Ausschluss von
        Ansprüchen des Mitarbeiters </span>
    </p>

    <p
      class="MsoNormal"
      style="text-align:justify;line-height:normal"
    >
      <span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >a. Ansprüche des
        Mitarbeiters aus dem Arbeitsverhältnis verfallen, wenn sie nicht innerhalb
        einer Ausschlussfrist von vier Monaten nach Fälligkeit gegenüber dem
        Arbeitgeber in Textform zumindest dem Grunde nach geltend gemacht werden.</span>
    </p>

    <p
      class="MsoNormal"
      style="text-align:justify;line-height:normal"
    >
      <span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >Lehnt der Arbeitgeber
        die Ansprüche schriftlich ab, verfallen die Ansprüche des Mitarbeiters, wenn
        sie nicht innerhalb von einer weiteren Ausschlussfrist von vier Monaten ab
        Zugang der schriftlichen Ablehnung zumindest dem Grunde nach gerichtlich
        geltend gemacht werden.</span>
    </p>

    <p
      class="MsoNormal"
      style="text-align:justify;line-height:normal"
    >
      <span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >b. Diese
        Ausschlussfristen gelten nicht für Ansprüche des Mitarbeiters, die kraft
        Gesetzes diesen Ausschlussfristen entzogen sind, beispielsweise</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">-<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >Ansprüche aus einem nach
        dem Arbeitnehmer-Entsendegesetz für allgemeinverbindlich erklärten Tarifvertrag
        und Ansprüche aus einer Rechtsverordnung nach dem Arbeitnehmer-Entsendegesetz
        (wobei sich aus der jeweiligen Mindestlohnregelung Ausschlussfristen ergeben
        können und diese dann einzuhalten sind), </span>
    </p>

    <p
      class="MsoNormal"
      style="margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">-<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >Ansprüche aus dem
        Mindestlohngesetz,</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">-<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >Ansprüche aus einer
        Betriebsvereinbarung oder </span>
    </p>

    <p
      class="MsoNormal"
      style="margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">-<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >Ansprüche aus einem nach
        dem Tarifvertragsgesetz anwendbaren Tarifvertrag.</span>
    </p>

    <p
      class="MsoNormal"
      style="text-align:justify;line-height:normal"
    >
      <span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >Diese Ausschlussfristen
        gelten außerdem nicht für </span>
    </p>

    <p
      class="MsoNormal"
      style="margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal"
    >
      <span
        lang="X-NONE"
        style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif"
      >-<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span><span
        lang="X-NONE"
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >Ansprüche
        aus § 8 Absatz 5 Arbeitnehmerüber-lassungsgesetz i.V.m. einer Verordnung über
        eine Lohnuntergrenze in der Arbeitnehmer</span><span
        style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif"
      >-</span><span
        lang="X-NONE"
        style="font-size:
9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >überlassung</span><span
        style="font-size:
9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >,</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">-<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >Schäden aus der Verletzung
        des Lebens, des Körpers oder der Gesundheit, die auf einer vorsätzlichen oder
        fahrlässigen Pflichtverletzung des Arbeitgebers oder einer vorsätzlichen oder
        fahrlässigen Pflichtverletzung eines gesetzlichen Vertreters oder
        Erfüllungsgehilfen des Arbeitgebers beruhen,</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">-<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >sonstige Schäden, die
        auf einer vorsätzlichen oder grob fahrlässigen Pflichtverletzung des
        Arbeitgebers oder auf einer vorsätzlichen oder grob fahrlässigen
        Pflichtverletzung eines gesetzlichen Vertreters oder Erfüllungsgehilfen des Arbeitgebers
        beruhen oder</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-left:18.0pt;text-align:justify;text-indent:
-18.0pt;line-height:normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">-<span
        style="font:7.0pt &quot;Times New Roman&quot;"
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >Ansprüche des
        Mitarbeiters, die auf einer unerlaubten Handlung des Arbeitgebers oder auf
        einer unerlaubten Handlung eines gesetzlichen Vertreters oder
        Erfüllungsgehilfen des Arbeitgebers beruhen. </span>
    </p>

    <p
      class="MsoNormal"
      style="text-align:justify;line-height:normal"
    >
      <span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >c. Ansprüche des
        Mitarbeiters, die nicht innerhalb dieser Ausschlussfristen geltend gemacht
        werden, sind ausgeschlossen.  </span>
    </p>

    <p
      class="MsoNormal"
      style="text-align:justify;line-height:normal"
    >
      <span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >(2) Ausschluss von
        Ansprüchen des Arbeitgebers </span>
    </p>

    <p
      class="MsoNormal"
      style="text-align:justify;line-height:normal"
    >
      <span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >a. Ansprüche des
        Arbeitgebers aus dem Arbeitsverhältnis verfallen, wenn sie nicht innerhalb
        einer Ausschlussfrist von zwei Monaten nach Fälligkeit schriftlich gegenüber
        dem Mitarbeiter geltend gemacht werden.  </span>
    </p>

    <p
      class="MsoNormal"
      style="text-align:justify;line-height:normal"
    >
      <span
        style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif"
      >Lehnt der Mitarbeiter
        die Ansprüche in Textform ab, verfallen die Ansprüche des Arbeitgebers, wenn
        sie nicht innerhalb von einer weiteren Ausschlussfrist von zwei Monaten ab
        Zugang der in Textform erklärten Ablehnung gerichtlich geltend gemacht werden.</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">b.
        Ansprüche des Arbeitgebers, die nicht innerhalb dieser Ausschlussfristen
        geltend gemacht werden, sind ausgeschlossen.</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">Köln, {{ Sozlesme.imza_tarihi
      }}</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">____________________________________</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">(Ort /
        Datum / Unterschrift des Arbeitgebers)</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">Köln, {{
        Sozlesme.imza_tarihi }}</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">____________________________________</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">(Ort /
        Datum / Unterschrift des Mitarbeiters)</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">Der
        Mitarbeiter bestätigt durch seine Unterschrift das Merkblatt für
        Leiharbeitnehmerinnen und Leiharbeitnehmer der Bundesagentur für Arbeit, ein
        Exemplar dieses Arbeitsvertrages, eine Information über die Verarbeitung seiner
        personenbezogenen Daten und eine Verpflichtung auf das Datengeheimnis, sowie
        folgende weitere Unterlagen erhalten zu haben:</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">Köln, {{
        Sozlesme.imza_tarihi }}</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom:0cm;text-align:justify;line-height:
normal"
    >
      <span style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">____________________________________<br>
        (Ort / Datum / Unterschrift des Mitarbeiters)</span>
    </p>
    <!-- Sözleşme Bitiş SON -->
    <!-- Sözleşme Bitiş SON -->
    <!-- Sözleşme Bitiş SON -->
    <!-- Sözleşme Bitiş SON -->
  </div>
</template>

<script>
import Print from '@/layouts/print'

export default {
  name: 'SozlesmePrint',
  components: { Print },
  computed: {
    Sozlesme() {
      return this.$store.state.Sozlesmeler.Sozlesme
    },
  },
  mounted() {
    setTimeout(() => {
      window.print()
    }, 1000)
  },
  methods: {
    Yazdir() {
      window.print()
    },
  },
}

</script>

<style>
@media screen {
  .v-application--wrap {
    background: #938f8f;
  }
  .WordSection1 {
    display: flex;
    width: 800px;
    flex-direction: column;
    background: #ffffff;
    margin: 40px auto;
    padding: 20px;
    -webkit-box-shadow: 1px 5px 16px 12px rgba(0,0,0,0.74);
    box-shadow: 1px 5px 16px 12px rgba(0,0,0,0.74);
  }
  p {
    color: black
  }
  .print {
    display: flex;
    width: 150px;
    height: 80px;
    position: fixed;
    top:40px;
    right: 20%;
    /*background: red;*/
    font-size: 2rem;
    color: #000000;
    background: #fcfcfc;
    padding: 1rem;
    border-radius: 10px;
    border:1px solid #9b81e2;
  }
}
@media print {
  /**
    BİZİM YAZDIĞIMIZ STYLE BAŞLANGIÇ
   */
  .print {
    display: none;
  }
  .WordSection1 {
    background: #ffffff;
  }

  body {
    word-wrap: break-word;
    color: #000000;
  }

  p {
    color: black
  }
  /**
    BİZİM YAZDIĞIMIZ STYLE SON
   */

  @font-face {
    font-family: Wingdings;
    panose-1: 5 0 0 0 0 0 0 0 0 0;
  }
  @font-face {
    font-family: "Cambria Math";
    panose-1: 2 4 5 3 5 4 6 3 2 4;
  }
  @font-face {
    font-family: Calibri;
    panose-1: 2 15 5 2 2 2 4 3 2 4;
  }
  @font-face {
    font-family: "Segoe UI";
    panose-1: 2 11 5 2 4 2 4 2 2 3;
  }
  /* Style Definitions */
  p.MsoNormal, li.MsoNormal, div.MsoNormal {
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8.0pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 11.0pt;
    font-family: "Calibri", sans-serif;
  }

  p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph {
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8.0pt;
    margin-left: 36.0pt;
    line-height: 107%;
    font-size: 11.0pt;
    font-family: "Calibri", sans-serif;
  }

  p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst {
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 0cm;
    margin-left: 36.0pt;
    line-height: 107%;
    font-size: 11.0pt;
    font-family: "Calibri", sans-serif;
  }

  p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle {
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 0cm;
    margin-left: 36.0pt;
    line-height: 107%;
    font-size: 11.0pt;
    font-family: "Calibri", sans-serif;
  }

  p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast {
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8.0pt;
    margin-left: 36.0pt;
    line-height: 107%;
    font-size: 11.0pt;
    font-family: "Calibri", sans-serif;
  }

  p.einzug1, li.einzug1, div.einzug1 {
    mso-style-name: einzug1;
    margin-right: 0cm;
    margin-left: 0cm;
    font-size: 12.0pt;
    font-family: "Times New Roman", serif;
  }

  .MsoChpDefault {
    font-family: "Calibri", sans-serif;
  }

  .MsoPapDefault {
    margin-bottom: 8.0pt;
    line-height: 107%;
  }

  /* Page Definitions */
  @page WordSection1 {
    size: 595.3pt 841.9pt;
    margin: 70.9pt 70.9pt 2.0cm 70.9pt;
  }

  div.WordSection1 {
    page: WordSection1;
  }

  /* List Definitions */
  ol {
    margin-bottom: 0cm;
  }

  ul {
    margin-bottom: 0cm;
  }
}
</style>
